import React, { useState } from 'react'
import Layout from '../../components/common/Layout'
import Fade from 'react-reveal/Fade'
import CTA from '../../components/common/CTA'
import FAQ from '../../components/common/FAQ'
import PricingPlans from '../../components/pricing/PricingPlans'
import PageHeader from '../../components/common/PageHeader'
import ContentSection from '../../components/common/ContentSection'
import ElevateSection from '../../components/common/ElevateSection'
import Button from '../../components/common/Button'
import { Space } from '@mantine/core'
import Spotlight from '../../components/home/Spotlight'

const BuildingsRealEstatePage = () => {
  return (
    <Layout
      meta={{
        description:
          'Whether commercial or residential, real estate firms, funds, and consultants are elevating their market analysis with GIS',
        title: 'Atlas for Buildings & Real Estate',
        type: 'website'
      }}
      title={'Atlas for Buildings & Real Estate'}
    >
      <main>
        <Fade up duration={1000} delay={0} distance="30px">
          <PageHeader
            title={'Buildings & Real Estate'}
            description={
              'Whether commercial or residential, real estate firms, funds, and consultants are elevating their market analysis with GIS'
            }
            video="https://cdn.atlas.co/landing-pages/buildings-hero.mp4"
          />
        </Fade>
        <Fade up duration={1000} delay={0} distance="30px">
          <ContentSection centered>
            <h2>Gain market insights faster</h2>
            <p>
              The future success of retail, real estate, and restaurants will
              largely depend on the competitive advantages gained from investing
              in and implementing smart technology.
            </p>
          </ContentSection>
        </Fade>
        <Fade up duration={1000} delay={0} distance="30px">
          <ContentSection
            video={'https://cdn.atlas.co/landing-pages/investment-analysis.mp4'}
            icon={{ src: '/icons/piggy-bank.svg', color: '#FFBF6E' }}
          >
            <h3>Investment Analysis</h3>
            <p>
              Every property has a coordinate, making it easy to visualize
              assets and investment opportunities. However, identifying property
              investment opportunities goes beyond location; it’s about
              understanding why certain properties are more or less attractive.
            </p>
            <p>
              Whether it’s shared workspaces, data centers, or shopping mall
              locations, incorporating spatial variables into your analysis is
              essential.
            </p>
          </ContentSection>
        </Fade>

        <Fade up duration={1000} delay={0} distance="30px">
          <ContentSection
            video={'https://cdn.atlas.co/landing-pages/home-buffer.mp4'}
            reverse
            icon={{ src: '/icons/blueprint.svg', color: '#FFF4E7' }}
          >
            <h3>Site Planning</h3>
            <p>
              For Commercial Real Estate developers, using multiple location
              data streams in the site selection process is crucial for
              understanding catchment and trade areas for final clients.
            </p>
            <p>
              Whether it's for retail sites, corporate offices, or industrial
              units, incorporating new datasets on consumer insights and human
              mobility can be the key to justifying pricing and closing deals
              over competitors.
            </p>
          </ContentSection>
        </Fade>

        <Fade up duration={1000} delay={0} distance="30px">
          <ContentSection
            video={'https://cdn.atlas.co/landing-pages/dynamic-dashboards.mp4'}
            icon={{
              src: '/icons/strategy.svg',
              color: '#C2E5FF'
            }}
          >
            <h3>Trade Area Analysis</h3>
            <p>
              Understanding the spatial context is crucial to the commercial
              real estate market. An inadequate business location can determine
              the success or failure of a business, regardless of the quality of
              its products or services.
            </p>
          </ContentSection>
        </Fade>

        <Fade up duration={1000} delay={0} distance="30px">
          <ElevateSection
            title={'Designed to be easily updated'}
            subtitle={
              'Web maps are live assets designed to be edited, and any changes will be automatically updated synced across all members.'
            }
          />
        </Fade>

        <Fade up duration={1000} delay={0} distance="30px">
          <Spotlight />
        </Fade>

        <Fade up duration={1000} delay={0} distance="30px">
          <CTA text="Ready to level up your map-making process?" />
        </Fade>
      </main>
    </Layout>
  )
}

export default BuildingsRealEstatePage
